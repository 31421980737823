<template>
    <div class="page-box content-bg">
        <div class="page-content">
            <div class="bar-button query-form">
				<el-form :inline="true" label-position="left" @submit.native.prevent>
					<el-form-item  prop="resourceName" v-has-permi="['sEduSeduEduSource:list']">
						<el-input v-model="resourceName" placeholder="搜索资源名称" clearable ></el-input>
					</el-form-item>
					<el-form-item  prop="departmentName" v-has-permi="['sEduSeduEduSource:list']">
						<el-select v-model="departmentName" placeholder="请选择组织机构" multiple collapse-tags clearable
							@focus="showTreeDialog" @clear="showTreeClear" class="range-select">
							<el-option label="" value="" class="scope-selecst"></el-option>
							<el-option v-for="item in departmentNameOptions" :label="item.name" :value="item.id" :key="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item  prop="">
						<el-button  @click="searchClcik" v-has-permi="['sEduSeduEduSource:list']" :disabled="sortState" type="primary">
							查询
						</el-button>
                        <div class="line"></div>
						<el-button @click="additional" v-has-permi="['sEduSeduEduSource:add']" :disabled="sortState" type="primary">
							添加
						</el-button>
					</el-form-item>
				</el-form>
                <div style="margin-left:auto;">
                    <el-button v-if="!sortState" v-has-permi="['sEduSeduEduSource:sort']" @click="sortStateClcik" type="" class="sort-button">
							排序
					</el-button>
					<el-button v-if="sortState" @click="saveSort" type="" class="sort-button">
						完成
					</el-button>
					<el-button  @click="classifyManage" v-has-permi="['sEduSeduEduSource:type']" :disabled="sortState" class="classify-button">
						分类管理
					</el-button>
					<el-button  @click="batchAccredit" v-has-permi="['sEduSeduEduSource:batchAuth']" :disabled="sortState">
						批量授权
					</el-button>
                </div>
            </div>
			<div v-loading="tableDataLoading" element-loading-text="加载中..." style="min-height: 300px;">
				<div class="resource-box" v-if="mainDataList.length > 0" >
					<div class="resource-boes-rt"  @scroll="onScroll">
						<div v-if="!sortState" class="list-box" id="contents">
							<div v-for="(item, index) in mainDataList" class="anchor-item" :key="item.id" :id="`content-item${index}`">
								<div class="resource-cell">
									<div class="resource-cell-name">
										<div class="resource-cells-name_icon"></div>
										<div class="resource-cells-name_text">{{ item.type }}</div>
									</div>

									<div class="resource-cell-info" v-if="item.resourcesList.length > 0">
										<div v-for="sitem in item.resourcesList" :key="sitem.id" class="resource-rows" style="cursor: pointer;"
											@mouseover="buttonShow(sitem.id)" @mouseout="buttonHide" @click="routerPage(sitem)">
											<div class="resource-row-icon">
												<img :src="imageCount(sitem.name, sitem.cover)" class="cover-img">
											</div>
											<div class="resource-row-info" >
												<div class="resource-row-info_title">{{ sitem.name }}</div>
												<div style="display: flex; justify-content: space-between;">
													<div style="width: 90%;">
														<div v-if="sitem.descNote">
															<el-tooltip placement="bottom">
																<div slot="content">
																	<div style="font-size: 14px; font-weight: 600;">
																		{{ sitem.name }}
																	</div>
																	<div style="font-size: 12px;">
																		{{ sitem.descNote }}
																	</div>
																</div>
																<div class="resource-row-info_remark" style="width: 100%;">
																	{{ sitem.descNote }}
																</div>
															</el-tooltip>
														</div>
														<div v-else class="resource-row-info_url"  style="width: 90%;">{{ sitem.url }}</div>
													</div>
													<div v-if="sitem.createBy === createById || isSuper == '1'">
														<div v-show="isButtonShow === sitem.id">
															<el-dropdown  @command="dropdownCommand" @visible-change="dropdownChange">
																<span class="el-dropdown-link btn-icon"></span>
																<el-dropdown-menu slot="dropdown">
																	<el-dropdown-item :command="`账号管理-${sitem.id}-${sitem.account}-${sitem.password}-${sitem.createBy}`" >
																	   <i class="el-icon-edit-outline"></i> 账号管理
																	</el-dropdown-item>
																	<el-dropdown-item v-has-permi="['sEduSeduEduSource:edit']"
																	   :command="`编辑-${sitem.id}-${sitem.name}-${sitem.url}-${sitem.cover}-${sitem.typeId}-${sitem.descNote}-${sitem.cover}`">
																	   <i class="el-icon-edit-outline"></i> 编辑
																	</el-dropdown-item>
																	<el-dropdown-item v-has-permi="['sEduSeduEduSource:del']" :command="`删除-${sitem.id}`"  style="color: #f26231">
																	   <i class="el-icon-delete"></i> 删除
																	</el-dropdown-item>
																</el-dropdown-menu>
															</el-dropdown>
														</div>
													</div>
													<div v-else >
														<div v-show="isButtonShow === sitem.id">
															<el-dropdown  @command="dropdownCommand" @visible-change="dropdownChange">
																<span class="el-dropdown-link btn-icon"></span>
																<el-dropdown-menu slot="dropdown">
																	<el-dropdown-item :command="`账号管理-${sitem.id}-${sitem.account}-${sitem.password}-${sitem.createBy}`" >
																	   <i class="el-icon-edit-outline"></i> 账号管理
																	</el-dropdown-item>
																</el-dropdown-menu>
															</el-dropdown>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<!-- 排序 -->
						<div v-if="sortState"  class="list-box"  id="contentes">
							<div v-for="item in mainDataList" class="" :key="item.id">
								<div class="resource-cell">
									<div class="resource-cell-name">
										<div class="resource-cells-name_icon"></div>
										<div class="resource-cells-name_text">{{ item.type }}</div>
									</div>
									<draggable v-model="item.resourcesList" class="resource-cell-info"
										group="people" @start="itemDrag = true" @end="itemsDragEnd">
										<div v-for="sitem in item.resourcesList" :key="sitem.id" :data-id="sitem.id" :id="'ele' + item.id" class="resource-rows resource-roes"
											@mousedown="getSrot(item, sitem.id)" style="position: relative; height: 82px;">
											<div class="resource-row-icon">
												<img :src="imageCount(sitem.name, sitem.cover)" class="cover-img"/>
												<div class="drag-layer">
													<i class="el-icon-rank"></i>
													<span>拖动排序</span>
												</div>
											</div>
											<div class="resource-row-info">
												<div class="resource-row-info_title">{{ sitem.name }}</div>
												 <div slot="reference" class="resource-row-info_remark" v-if="sitem.descNote">{{ sitem.descNote }}</div>
												<div class="resource-row-info_url" v-else>{{ sitem.url }}</div>
											</div>
										</div>
									</draggable>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-else slot="empty">
					<div v-if="mainDataState">
						<div style="width: 96px; margin: 180px auto 0;">
							<img src="../../assets/images/no_record.png" width="94" height="70" />
						</div>
						<p style="color: #333; font-size: 14px; text-align: center;">暂无数据</p>
					</div>
				</div>
			</div>
        </div>

		<!-- 资源新增和编辑弹窗 -->
        <dialog-wrapper :dialogObj="dialogData" @handleClose="dialogClose">
            <el-form :model="formData" :rules="formRules" label-width="94px" ref="formRef" label-posiiton="right"
				class="resource-add-from common-form" v-loading="formData.loading" :element-loading-text="formData.loadingText">
				<el-form-item label="资源分类" prop="typeId">
				    <el-select v-model="formData.typeId" placeholder="请选择资源分类" clearable class="input-width-medium">
				    	<el-option v-for="item in classifyOptions" :label="item.type"
				    		:value="item.id" :key="item.id">
				    	</el-option>
				    </el-select>
					<div class="resource-add-from_class" v-has-permi="['sEduSeduEduSource:type']">
						<div style="color: #999; font-size: 12px;">没找到想要的分类？</div>
						<div style="color: #5ba7f3; font-size: 12px; cursor: pointer;" @click="classifyManage">创建分类</div>
					</div>
				</el-form-item>
				<el-form-item label="链接地址" prop="url">
					<!-- <el-input v-model="formData.url" placeholder="请输入链接地址 例:https://www.zxxk.com" clearable  maxlength="100"></el-input> -->
					<div class="resource-url">
						<el-input v-model="formData.url" placeholder="请输入链接地址 例:https://www.zxxk.com" clearable  maxlength="100"></el-input>
						<div style="width: 100px; color: #5ba7f3; font-size: 14px; cursor: pointer; text-align: right;"  @click="getLink">
							自动获取
						</div>
					</div>
				</el-form-item>
                <el-form-item label="资源名称" prop="name">
                    <el-input v-model="formData.name" placeholder="请输入资源名称" clearable maxlength="20" show-word-limit class="input-width-medium"></el-input>
                </el-form-item>
                <el-form-item label="资源封面" prop="cover">
					<el-upload class="avatar-uploader" action="/api/school/common/uploadFile"
						accept=".png,.jpg,.jpeg,.bmp,.gif"
						:show-file-list="false"
						:headers="headers"
						:on-success="uploadSuccess"
						:before-upload="uploadUpload">
						<div @mouseenter="isCoveragse()" @mouseleave="notCoveragse()" style="height: 70px;">
							<img v-if="formData.cover" :src="formData.cover" class="avatar">
							<i v-if="formData.cover === ''" class="el-icon-plus avatar-uploader-icon"></i>
							<div v-if="formData.cover">
								<el-collapse-transition name="el-zoom-in-center">
									<div class="coverage" v-show="isCoverage">
										<div class="coverage-rows coverage-delete" @click.stop="deleteClick">
											<i class="el-icon-delete"></i>
										</div>
										<div class="coverage-rows coverage-upload">
											<i class="el-icon-upload2"></i>
										</div>
									</div>
								</el-collapse-transition>
							</div>
						</div>
						<div slot="tip" class="el-upload__tip">
							支持jpg.jpeg.bmp.gif.png类型文件，5M以内
						</div>
					</el-upload>
                </el-form-item>
				<el-form-item label="资源描述" prop="descNote">
				    <el-input type="textarea" placeholder="请编辑资源说明" v-model="formData.descNote" class="input-width-medium"
						rows="6" maxlength="100" show-word-limit></el-input>
				</el-form-item>
				<el-form-item label="可见范围" prop="scope">
				    <el-select v-model="formData.scope" placeholder="请选择可见范围" multiple collapse-tags
						@focus="getScopeInfo" class="range-select input-width-medium">
				    	<el-option label="" value="" class="scope-select"></el-option>
						<el-option v-for="item in formData.scopeOptions" :label="item.name" :value="item.id" :key="item.id"></el-option>
				    </el-select>
				</el-form-item>
				<div style="text-align: right;width:546px;">
                		<el-button :disabled="disabledState" @click="saveItem" type="primary">确定</el-button>
                		<el-button :disabled="disabledState" @click="dialogClose">取消</el-button>
            	</div>
            </el-form>

        </dialog-wrapper>

        <dialog-wrapper :dialogObj="userInfoData" @handleClose="userInfoClose">
            <el-form :model="userInfoForm" :rules="userInfoRules" label-width="94px" ref="userInfoRef" class="common-form">
                <el-form-item label="账号" prop="account">
                    <el-col :span="22">
                        <el-input v-model="userInfoForm.account" placeholder="请输入资源名称" class="input-width-medium"></el-input>
                    </el-col>
                    <el-col :span="2">
                        <div style="text-align: right">
                            <i @click="textCopy(userInfoForm.account)" class="el-icon-document-copy"></i>
                        </div>
                    </el-col>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-col :span="22">
                        <el-input v-model="userInfoForm.password" placeholder="请输入资源名称" class="input-width-medium"></el-input>
                    </el-col>
                    <el-col :span="2">
                        <div style="text-align: right">
                            <i @click="textCopy(userInfoForm.password)" class="el-icon-document-copy"></i>
                        </div>
                    </el-col>
                </el-form-item>
				<div style="text-align: right;width:546px;">
                	<el-button :disabled="disabledState" @click="saveUserInfo" type="primary" >确定</el-button>
                	<el-button :disabled="disabledState" @click="userInfoClose">取消</el-button>
            	</div>
            </el-form>

        </dialog-wrapper>

		<!-- 分类管理 -->
		<dialog-wrapper :dialogObj="dengmiQueryClassify" @handleClose="classifyClose">
			<div v-loading="dengmiQueryClassify.loading">
				<div class="add-classify">
					<el-button type="primary"  @click="addDomain">添加分类</el-button>
				</div>
				<div style="height: 436px; overflow-y: auto; margin: 20px 0 50px;">
					<el-form :model="dengmiQueryClassify" ref="dengmiQueryClassify" class="classify-form">
						<draggable v-model="dengmiQueryClassify.classifyList" group="people" @start="drag = true" @end="dragend">
							<el-form-item v-for="(itme, index) in dengmiQueryClassify.classifyList"
								class="classify-cell"
								:key="itme.key"
								:prop="'classifyList.' + index + '.type'"
								:rules="{required: true, message: '请输入分类名称', trigger: 'blur'}">
								<div class="move-boxs-info">
									<div class="move-box-cr"></div>
									<div class="move-box-cr"></div>
									<div class="move-box-cr"></div>
									<div class="move-box-cr"></div>
									<div class="move-box-cr"></div>
									<div class="move-box-cr"></div>
								</div>
								<el-input v-if="itme.id == ''" v-model="itme.type" maxlength="10" show-word-limit @blur="addBlur(itme)"></el-input>
								<el-input v-else v-model="itme.type" maxlength="10" show-word-limit @blur="editBlur(itme)"></el-input>
								<i v-if="dengmiQueryClassify.classifyList.length > 1 && itme.id === ''" class="el-icon-delete" @click.prevent="removeDomain(itme)"></i>
								<i v-if="dengmiQueryClassify.classifyList.length > 1 && itme.id !== ''" class="el-icon-delete" @click.prevent="removesDomain(itme)"></i>
							</el-form-item>
						</draggable>
					</el-form>
				</div>
				<div style="height: 30px;"></div>
				<div style="text-align: right">
				    <el-button @click="saveClassify" type="primary">保存</el-button>
				</div>
			</div>
		</dialog-wrapper>

		<!-- 批量授权 -->
		<dialog-wrapper :dialogObj="dengmiQueryAccredit" @handleClose="accreditClose">
			<div style="color: #999; font-size: 12px; text-align: center;">
				可在本页面批量配置教学资源的可见范围，只能配置您创建的资源
			</div>
			<div style="height: 16px;"></div>
			<el-form :model="dengmiQueryAccredit" label-width="94px" ref="dengmiQueryAccredit" class="common-form"
				label-position="right"  :rules="accreditRef">
				<el-form-item label="资源选择" prop="">
					<el-checkbox v-model="dengmiQueryAccredit.allResource" @change="allsResourceChange">全选</el-checkbox>
				</el-form-item>
				<div class="limits-list">
					<el-form-item v-for="item in dengmiQueryAccredit.dataList" :key="item.id" :label="item.type"
						prop="" class="resource-item">
						<el-checkbox @change="val => handleCheckAllChange(val, item, item.id)"
							:indeterminate="item.isIndeterminate" v-model="item.checkAll">
							全选
						</el-checkbox>
						<div style="margin: 8px 0;"></div>
						<el-checkbox-group v-model="dengmiQueryAccredit.checkedCities" @change="sval => handleCheckedCitiesChange(sval, item)">
							<el-checkbox v-for="sitem in item.resourcesList" :label="sitem.id" :key="sitem.id">{{ sitem.name }}</el-checkbox>
						</el-checkbox-group>
					</el-form-item>
				</div>

				<el-form-item label="可见范围" prop="scope">
					<el-select v-model="dengmiQueryAccredit.scope" placeholder="请选择可见范围" multiple collapse-tags
						@focus="getScopesInfo" class="range-select input-width-medium">
						<el-option label="" value="" class="scopes-select"></el-option>
						<el-option v-for="item in dengmiQueryAccredit.scopeOptions" :label="item.name" :value="item.id" :key="item.id"></el-option>
					</el-select>
				</el-form-item>
				<div style="height: 30px;"></div>
				<div style="text-align: right;width:546px;">
			    	<el-button :disabled="disabledState" @click="accreditConfirm" type="primary" >确定</el-button>
			    	<el-button :disabled="disabledState" @click="accreditCancel">取消</el-button>
				</div>
			</el-form>


		</dialog-wrapper>
		<!--人员选择弹窗-->
		<xk-tree-shape
		    v-if="dengmiQueryPeople.dialogVisible"
		    title="组织架构"
		    :show="dengmiQueryPeople.dialogVisible"
		    :list="selTreeOptList"
		    :selectObjData="selectObjData"
		    @getNextLevelTreeList="getNextLevelTreeList"
		    @determine="determine"
		    @closeDialog="closeDialog">
		</xk-tree-shape>

    </div>
</template>

<script>
import {mapState} from "vuex";
import {DialogWrapper} from "common-local";
import {getToken} from "@/libs/auth";
import draggable from "vuedraggable";
import { configure, comparisonTable,zjwConfig } from "@/assets/data/teachingResourcesDocking.js";
import { CommonModel } from '@/models/Common.js';
import { removeTreeShapeDialog } from '@/libs/utils.js'

export default {
    name: "SEduSeduEduSourceBox",
    components: {DialogWrapper, draggable},
    data() {
        return {
			/**
			 * title 名称
			 * multiple 是否多学
			 * treeData 树形请求数据
			 * department 部门
			 * selTreeOptList 请求数据
			 * selectObjData 选中数据
			 * setTreeAuxiliaryData 返回后的辅助数据
			 * */
			title: '可选择',
			multiple: false,
			treeData: {
			    schoolId: '',
			    type: 'teacher,organ',
			    parentId: '',
			    name: '',
			    organType: '1,5'
			},
			facultyStaff: {
			    type: 'teacher,organ',
			    organType: '1,5'
			},
			department: {
			    type: 'organ',
			    organType: '1'
			},
			selectYetData: [],
			selectAddYetData: [],
			selectBatchYetData: [],
			selTreeOptList: [],
			selectObjData: [],
			setTreeAuxiliaryData: {
			    key: '',
			    data: [],
			    selList: '',
			},
			// 是否管理有
			isSuper: "",
			ruleForm: {},
			selectType: "",
			resourceName: "",
			departmentName: [],
			departmentNameOptions: [],
			// 资源分类
			classifyOptions: [],
            defaultArr: ["学库网", "菁优网", "学科网"],
			disabledState: false,
            sortState: false,
            itemDrag: true,
			drag: true,
			sortDataId: "",
			// 是否是自己创建的应用标识
			createById: "",
            mainDataList: [],
			mainDataState: false,
            dialogData: {
                dialogVisible: false,
                title: "添加资源",
                width: "640px"
            },
			activeIndex: 0,
			// 锚点集合
			anchorPointList: [],
			// 更多按钮显示、隐藏控制
			isButtonShow: false,
			isButtonShowVal: "",
			isButtonShowId: "",
			// 重新上传显示、隐藏控制
			isCoverage: false,
			// 数据列表加载动画
			tableDataLoading: true,
            formData: {
				loading: false,
				loadingText: "",
				// 数据主键
				id: "",
				// 资源分类
				typeId: "",
				// 资源名称
                name: "",
				// 地址链接
                url: "",
				// 资源封面
                cover: "",
				// 资源描述
				descNote: "",
				// 可见范围
				scope: [],
				scopeOptions: []
            },
            formRules: {
				typeId: [{
                    required: true,
                    message: "请选择资源分类",
                    trigger: "blur"
                }],
                name: [{
                    required: true,
                    message: "请输入资源名称",
                    trigger: "blur"
                }],
                url: [{
                    required: true,
                    message: "请输入资源地址",
                    trigger: "blur"
                }, {
					trigger: "blur",
					validator: (rule, value, callback) => {
						let regExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/;
						if(!regExp.test(value)) {
							callback("请输入正确的链接地址！")
						} else {
							callback();
						}
					}
				}],
				scope: [{
                    required: true,
                    message: "请选择可见范围",
                    trigger: "blur"
                }]
            },
            userInfoData: {
                dialogVisible: false,
                title: "账号密码",
                width: "640px"
            },
			// 修改账号密码
            userInfoForm: {
				createById: "",
                id: "",
                account: "",
                password: ""
            },
            userInfoRules: {
                account: [{
                    required: true,
                    message: "请输入用户账号",
                    trigger: "blur"
                }],
                password: [{
                    required: true,
                    message: "请输入用户密码",
                    trigger: "blur"
                }]
            },
			// 分类管理
			dengmiQueryClassify: {
				// 弹窗显示/隐藏控制
				dialogVisible: false,
				// 加载动画
				loading: true,
				title: "分类管理",
				width: "640px",
				// 源对象的下标
				dragIndex: "",
				// 目标对象的下标
				enterIndex: "",
				timeout: null,
				classifyList: [{
					id: "",
					sort: "",
				    type: "",
					key: Date.now()
				}]
			},
			// 人员选择弹窗
			dengmiQueryPeople: {
			    title: '选择年级/班级/学生',
			    dialogVisible: false,
			    width: '600px',
			    key: 1
			},
			accreditRef: {
				scope: [{
				    required: true,
				    message: "请选择可见范围",
				    trigger: "blur"
				}]
			},
			// 批量授权
			dengmiQueryAccredit: {
				// 弹窗显示/隐藏控制
				dialogVisible: false,
				// 加载动画
				loading: true,
				title: "批量授权",
				width: "640px",
				allResource: false,
				isIndeterminate: true,
				checkedCities: [],
				cities: [],
				dataList: [],
				// 可见范围
				scope: [],
				scopeOptions: [],
				batchAccreditState: false
			}
        };
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
            userId: (state) => state.userId,
			token: (state) => state.user.token,
            projectMark: (state) => state.projectMark,
        }),
        headers() {
            return {
                Authorization: getToken()
            };
        }
    },
    created() {
		this.createById = this.$store.state.userId;
		this.treeData.schoolId = this.$store.state.schoolId;
		// isSuper 是否管理员  0否 1是
		this.isSuper = this.$store.state.isSuper;
        this.mainDataGet();
    },
	mounted() {


	},
	// 离开当前界面
	destroyed() {
		// 每次离开当前界面时，清除定时器
		clearInterval(this.dengmiQueryClassify.timeout);
		this.dengmiQueryClassify.timeout = null;
	},
    methods: {
        imageCount(name, cover) {
            if (cover) {
                return cover;
            } else {
                if (name === "学科网") {
                    return require("../../assets/images/xuekewang.png");
                } else if (name === "菁优网") {
                    return require("../../assets/images/jingyou.png");
                } else if (name === "学库网") {
                    return require("../../assets/images/xuekubao.png");
                } else {
					return require("../../assets/images/resource_icon.png");
				}
            }
            return require("../../assets/images/moren.png");
        },
		/**
		 * @Description: 搜索
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-07 14:06:36
		 */
		searchClcik() {
			this.tableDataLoading = true;
			this.mainDataGet();
		},
		/**
		 * @Description: 教学资源分类列表
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-07 17:15:36
		 */
		handleClassifyList() {
			let dataList = [];
			let classifyDataList = [];
			console.log("弹窗类型== ",this.selectType)
			this._fet("/school/teachingResourceType/list", {
			    "schoolId": this.schoolId
			}).then((res) => {
			    if (res.data.code === "200") {
					dataList = res.data.data;
					dataList.map((e ,i)=> {
						classifyDataList.push({
							"id": e.id,
							"type": e.type,
							"sort": e.sort,
							"key": e.id,
							"schoolId": e.schoolId
						})
					});

					this.dengmiQueryClassify.classifyList = classifyDataList;
					if(this.selectType === "add") {
						this.classifyOptions = res.data.data;
						if(this.classifyOptions.length > 0) {
							this.formData.typeId = this.classifyOptions[0].id
						}
					} else if(this.selectType === "edit") {
						this.classifyOptions = res.data.data;
					}

					this.dengmiQueryClassify.loading = false;
			    } else if (res.data && res.data.msg) {
			        this.$message.error(res.data.msg);
					this.dengmiQueryClassify.loading = false;
			    }
			});
		},
		/**
		 * @Description: 资源详情数据
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-07 17:15:36
		 */
		handleDetailData(id) {
			let editDataList = [];
			let dataesList = [];
			this.formData.scope = [];
			this.formData.scopeOptions = [];
			this.selectObjData = [];
			this.selectType === "edit"
			this._fet("/school/teachingResource/detail", {
				id: id
			}).then((res) => {
				if (res.data.code === "200") {
					this.handleClassifyList();
					let reg = res.data.data;

					if(reg.permList.length > 0) {
						for(let k = 0; k < reg.permList.length; k++) {
							editDataList.push(reg.permList[k].bussinessId);
						}
					}

					for(let j = 0; j < reg.permList.length; j++) {
						dataesList.push({
							createBy: reg.permList[j].createBy,
							id: reg.permList[j].bussinessId,
							name: reg.permList[j].name,
							organType: reg.permList[j].organType,
							schoolId: reg.permList[j].schoolId,
							type: reg.permList[j].type,
							unionId: reg.permList[j].unionId
						})
					}
					this.selectObjData = dataesList;
					this.selectAddYetData = dataesList;


					this.formData = {
					    id: reg.id,
					    name: reg.name,
					    url: reg.url,
					    cover: reg.cover || require("../../assets/images/resource_icon.png"),
						typeId: reg.typeId,
						descNote: reg.descNote,
						scope: editDataList,
						scopeOptions: dataesList
					};
					this.dialogData.title = "编辑教学资源";

				} else if (res.data && res.data.msg) {
					this.$message.error(res.data.msg);
				}
			});
		},
		/**
		 * @Description: 人员部门搜索清空
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-07 14:06:36
		 */
		showTreeClear() {
			this.departmentName = [];
			this.departmentNameOptions = [];
			this.selectObjData = [];
			this.selectYetData = [];
		},
		/**
		 * @Description: 人员部门搜索弹窗
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-07 14:06:36
		 */
		showTreeDialog() {
			// console.log("人员部门搜索弹窗")
			// 弹窗是否开启多选
			// this.multiple = false;

			// 弹窗类型
			this.selectType = "query";
			// 隐藏下拉框
			setTimeout(()=>{
				let selectEle = document.getElementsByClassName("scope-selecst")[0];
				let selectDropdown = selectEle.parentNode.parentNode.parentNode.parentNode;
				selectDropdown.style.display = "none";
			},100);
			this.showFormTreeDialog();
		},
		/*
		    树形组件相关方法
		    showFormTreeDialog      打开表单中弹窗组件
		    closeTreeDialog         关闭弹窗组件
		    setTreeData             保存弹窗选中数据
		    filterTreeData          筛选人员弹窗数据
		    getTreeData             获取所有人员树形数据
		 */
		async showFormTreeDialog(data) {

		    // 重置被选中的数据
		    this.selectObjData =  [];
		    // 重置请求树形数据
		    this.treeData.name = '';
		    this.treeData.parentId = '';
			// 组织机构请求的类型
		    this.selectOrganization();
		    // 请求树形数据
		    await this.getTreeDataList()

			// 打开组织弹窗组件
		    this.dengmiQueryPeople.title = `选择组织机构`;
		    this.dengmiQueryPeople.dialogVisible = true;
		},
		/**
		 * @Description: 选择组织机构请求的类型
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 武东超
		 * @Date: 2023-04-23 14:04:02
		 */
		selectOrganization () {
		    this.treeData.type = this.facultyStaff.type;
		    this.treeData.organType = this.facultyStaff.organType;
		},
		closeTreeDialog() {
		    this.dengmiQueryPeople.dialogVisible = false;
		},
		getTreeData(data) {
		    return new Promise((resolve => {
		        const campusSafety = new CampusSafetyModel()
		        campusSafety.getIssueScopeByAccess({
		            schoolId: this.schoolId,
		            applyPerson: data
		        }).then((res) => {
		            resolve(res.data.data)
		        })
		    }))
		},
		async filterTreeData(type) {
		    // 1教师数据 2学生数据 3全部数据
		    switch (type) {
		        case 1:
		            if (this.teacherTreeData.length === 0) {
		                this.teacherTreeData = await this.getTreeData(1)
		            }
		            return this.teacherTreeData
		        case 2:
		            if (this.studentTreeData.length === 0) {
		                this.studentTreeData = await this.getTreeData(2)
		            }
		            return this.studentTreeData
		        case 3:
		            if (this.allTreeData.length === 0) {
		                this.allTreeData = await this.getTreeData(3)
		            }
		            return this.allTreeData
		        default:
		            return []
		    }
		},
		/**
		 * @Description:人员选择弹窗
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author:
		 * @Date: 2023-04-23 10:44:16
		 */
		 async getTreeDataList() {
		    console.log('人员选择弹窗this.treeData== ', this.treeData)
		    const commonModel = new CommonModel();
		    await commonModel.getAddressBookList(this.treeData).then((res) => {
		        if (res.data.code == '200') {
		            this.selTreeOptList = res.data.data;
					console.log("人员选择弹窗== ", this.selTreeOptList)
					if(this.selectType === "query") {
						this.selectObjData = this.selectYetData;
					} else if(this.selectType === "add") {
						this.selectObjData = this.selectAddYetData;
					} else if(this.selectType === "edit") {
						this.selectObjData = this.selectAddYetData;
					} else if(this.selectType === "batch"){
						this.selectObjData = this.selectBatchYetData
					}

		            console.log('初始化树形选择== ',this.selectObjData)
		        } else if (res.data && res.data.msg) {
		            this.$message.error(res.data.msg)
		        }
		    })
		},
		/**
		 * @Description: 组织机构请求下一级
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 武东超
		 * @Date: 2023-04-21 11:35:53
		 * @param {*} data
		 */
		async getNextLevelTreeList (data) {
		    const {id, name} = data;
		    this.treeData.parentId = id || null;
		    this.treeData.name = name;
		    await this.getTreeDataList();
		    this.$eventBroadcast("getNewTreeList");
		    console.log("组织机构请求下一级,请求完成== ", data);
		},
		/**
		 * @Description: 初始化树形数据
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 武东超
		 * @Date: 2023-04-21 11:04:31
		 */
		setCreatedTreeData (selTreeList) {
		    let arr = [];
		    let arr1 = [];
		    let ar1 =  this.getAllTreeDataItems(selTreeList, arr);
		    let treeName = this.getAllFirestTreeDataNames(selTreeList, arr1);
		    console.log("筛选出来的数据== ", ar1, treeName)
		    let firestCreatedData = {
		        data: ar1,
		        id: "",
		        index: "",
		        key: "initiate",
		        selList: treeName,
		        typeId: ""
		    }
		},
		getAllTreeDataItems (data, arr) {
		    data.forEach((item) => {
		        arr.push(item);
		        if (item.children) {
		            this.getAllTreeDataItems(item.children, arr);
		        }
		    })
		    return arr;
		},
		getAllFirestTreeDataNames (data, arr) {
		    data.forEach((item) => {
		        arr.push(item.name);
		    })
		    return arr;
		},
		/**
		 * @Description:人员选择弹窗选择结果
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author:
		 * @Date: 2023-06-10 14:38:39
		 */
		determine (data) {
			console.log("结果==== ", data);
			console.log("弹窗类型==== ", this.selectType);
			if(this.selectType === "add") {
				this.formData.scope = [];
				this.formData.scopeOptions = [];
				this.selectAddYetData = [];
				for(let j = 0; j < data.length; j++) {
					this.formData.scope.push(data[j].id)

				}
				this.formData.scopeOptions = data;
				this.selectAddYetData = data;
			} else if(this.selectType === "edit") {
				this.formData.scope = [];
				this.formData.scopeOptions = [];
				for(let j = 0; j < data.length; j++) {
					this.formData.scope.push(data[j].id)
				}
				this.formData.scopeOptions = data;
				this.selectAddYetData = data;
			} else if(this.selectType === "query") {
				this.departmentName = [];
				this.departmentNameOptions = [];
				for(let j = 0; j < data.length; j++) {
					this.departmentName.push(data[j].id)
				}
				this.departmentNameOptions = data;
				this.selectYetData= data;
			}  else if(this.selectType === "batch") {
				this.dengmiQueryAccredit.scope = [];
				this.dengmiQueryAccredit.scopeOptions = [];
				for(let j = 0; j < data.length; j++) {
					this.dengmiQueryAccredit.scope.push(data[j].id)
				}
				this.dengmiQueryAccredit.scopeOptions = data;
				this.selectBatchYetData = data;

			}

			this.setTreeData(data);
			this.setCreatedTreeData(data)
			removeTreeShapeDialog();
			this.dengmiQueryPeople.dialogVisible = false;
			this.treeData.parentId = "";
			this.treeData.name = "";
		},
		/**
		 * @Description:组织机构确定提交
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author:
		 * @Date: 2023-04-23 10:44:16
		 */
		setTreeData(data) {
		    console.log("组织机构提交数据== ", data)
			if(this.selectType === "add") {

			}
		    // 关闭弹窗
		    this.closeTreeDialog();
		},
		/**
		 * @Description: 关闭弹窗
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 武东超
		 * @Date: 2023-04-23 10:44:50
		 */
		closeDialog () {
		   this.dengmiQueryPeople.dialogVisible = false;
		   removeTreeShapeDialog();
		   this.treeData.parentId = '';
		   this.treeData.name = '';


		},
        // 弹窗关闭回调
        dialogClose() {
            this.dialogData.dialogVisible = false;
            this.$refs.formRef.resetFields();
            this.dialogData.title = "添加教学资源";
            this.formData = this.$options.data.call().formData;
			this.disabledState = false;
			if(this.selectType === "edit" || "add") {
				this.formData.scope = [];
				this.formData.scopeOptions = [];
				this.selectAddYetData = [];
				this.selTreeOptList = [];
				this.selectObjData = [];
			} else if(this.selectType === "batch") {
				this.dengmiQueryAccredit.scope = [];
				this.dengmiQueryAccredit.scopeOptions = [];
				this.selectBatchYetData = [];
			}
        },
		/**
		 * @Description: 更多按钮显示
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-09 14:16:28
		 */
		buttonShow(id) {
			this.isButtonShowId = id;
			this.isButtonShow = id;
		},
		/**
		 * @Description: 更多按钮隐藏
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-09 14:16:39
		 */
		buttonHide() {
			if(this.isButtonShowVal) {
				this.isButtonShow = this.isButtonShowId;
			} else {
				this.isButtonShow = false;
				this.isButtonShowId = "";
			}
		},
		/**
		 * @Description: 监听更多按钮事件
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-09 14:45:25
		 */
		dropdownChange(val) {
			this.isButtonShowVal = val;
			if(!val) {
				this.isButtonShow = false;
			}
		},
		/**
		 * @Description: 下拉菜单点击
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author:
		 * @Date: 2023-06-02 09:04:22
		 */
        dropdownCommand(value) {
			const valueArr = value.split("-");
			if (valueArr[0] === "删除") {
                this.$confirm("删除后其他可查看此网站的用户将无法查看该网站, 是否执行删除操作?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
					this._fet("/school/teachingResource/delete", {
						schoolId: this.schoolId,
						id: valueArr[1]
					}).then((res) => {
						if (res.data.code === "200") {
							this.$message.success(res.data.msg);
							this.tableDataLoading = true;
							this.mainDataGet();
						} else if (res.data && res.data.msg) {
							this.$message.error(res.data.msg);
						}
					});
                }).catch(() => {});
            } else if (valueArr[0] === "编辑"){
				this.selectType = "edit";
				this.handleDetailData(valueArr[1]);
                this.dialogData.dialogVisible = true;
            } else if(valueArr[0] === "账号管理") {
				if(valueArr[2] === "undefined") {
					this.userInfoForm = {
					    id: valueArr[1],
					    account: "",
					    password: "",
						createById: valueArr[4]
					};
				} else {
					this.userInfoForm = {
					    id: valueArr[1],
					    account: valueArr[2] || "",
					    password: valueArr[3] || "",
						createById: valueArr[4]
					};
				}

				this.userInfoData.dialogVisible = true;
			}
        },
        // 账号密码弹窗打开
        userInfoOpen(data) {
            this.userInfoForm = {
                id: data.id,
                account: data.account || "",
                password: data.password || "",
            };
            this.userInfoData.dialogVisible = true;
        },
        // 账号密码弹窗关闭
        userInfoClose() {
            this.userInfoData.dialogVisible = false;
            this.$refs.userInfoRef.resetFields();
            this.userInfoForm = this.$options.data.call().userInfoForm;
			this.selectObjData =  [];
			this.selectAddYetData = [];
			this.selTreeOptList = [];
			this.disabledState = false;
        },
		/**
		 * @Description: 查看列表详情(跳转第三方网站)
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author:
		 * @Date: 2023-06-02 09:05:13
		 */
        routerPage(data) {
			console.debug("data", JSON.parse(JSON.stringify(data)));
			let hasJumpUrlType = false;

			
			if(data.name == "学科网"){
				// 进入学科网
				console.debug("进入学科网");
				configure.map((item) => {
					if (item.jumpUrlSchoolId == data.schoolId && item.jumpUrlName == data.name) {
						hasJumpUrlType = true;
					}
				});
			}
			if(data.name == "组卷网"){
				// 进入组卷网
				console.debug("进入组卷网");
				zjwConfig.map((item) => {
                if (item.jumpUrlSchoolId == data.schoolId && item.jumpUrlName == data.name) {
						hasJumpUrlType = true;
					}
				});
			}

            if (hasJumpUrlType) {
                this._fet("/school/login/xkwAutoLogin", {
					userId: this.userId,
					projectMark: this.projectMark,
					path: 'JumpPage',
                    schoolId: data.schoolId,
					type: data.name == "组卷网" ? "zjw" : "xkw"
				}).then(
                    (res) => {
                        if (res.data.code === "200") {
                            console.log(res.data.data, "data-");
                            window.open(res.data.data, "_blank");
                        } else if (res.data && res.data.msg) {
                            this.$message.error(res.data.msg);
                        }
                    },
                );
            } else {
				const findItem = comparisonTable.filter(item => data.id === item.id)[0];
				if (findItem) {
					const fnList = this.joinArgs(data);
					fnList[findItem.fnName]();
				}
				console.log(data.url,'data-url')
                window.open(data.url, "_blank");
            }

        },
		joinArgs (data) {
			const token = this.token;
			const userId =  this.userId;
			return {
				'rC': () => {
					data.url += `?token=${token}&userId=${userId}`
				}
			}
		},
        // 文本一键复制
        textCopy(value) {
            const input = document.createElement("input");
            input.value = value;
            document.body.appendChild(input);
            input.select();
            document.execCommand("Copy");
            document.body.removeChild(input);
            this.$message({
                message: "复制成功",
                type: "success"
            });
        },
		// 左侧link点击右侧内容滑动
		jumpClick(item, index) {
		   this.activeIndex = index;
		   this.$nextTick(() => {
		   document.querySelector(`#content-item${index}`).scrollIntoView({
		   		behavior: "smooth", // 平滑过渡
		   		block: "start" // 上边框与视窗顶部平齐。默认值
		   	});
		   });
		},
		onScroll(e) {
			let scrollItems = document.querySelectorAll(".anchor-item");
			for (let i = scrollItems.length - 1; i >= 0; i--) {
			    // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
			    let judge = e.target.scrollTop >= scrollItems[i].offsetTop - scrollItems[0].offsetTop;
			    if (judge) {
					this.activeStep = i;
					break;
				}
			}
		},

		/**
		 * @Description: 教学资源数据列表（批量授权用）
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author:
		 * @Date: 2023-06-12 21:47:45
		 */
		handleBacthList() {
			let datasList = [];
			let resourcesNotDataList = [];
			let resourcesYetDataList = [];
			// queryOwnStatus 1表示自己创建的资源
		    this._fet("/school/teachingResource/list", {
		        schoolId: this.schoolId,
				queryOwnStatus: "1"
		    }).then((res) => {
		        if (res.data.code === "200") {
					datasList = res.data.data;
					for(let j = 0; j < datasList.length; j++) {
						for(let k = 0; k < datasList[j].resourcesList.length; k++) {
							if(datasList[j].resourcesList[k].createBy === this.createById) {
								// this.dengmiQueryAccredit.checkedCities.push(datasList[j].resourcesList[k].id);
							}
						}
					}

					for(let i = 0; i < datasList.length; i++) {
						if(datasList[i].resourcesList.length == 0) {
							resourcesNotDataList.push(datasList[i].resourcesList)
						} else {
							resourcesYetDataList.push(datasList[i].resourcesList)
						}
					}

					if(resourcesYetDataList.length == 0) {
						this.$message.warning("您未添加任何教学资源");
						this.dengmiQueryAccredit.batchAccreditState = true;
					}

					this.dengmiQueryAccredit.dataList = datasList.map(item => ({
						...item,
						checkAll: false,
						isIndeterminate: true
					}))

		        } else if (res.data && res.data.msg) {
		            this.$message.error(res.data.msg);
		        }
		    });
		},

		/**
		 * @Description: 教学资源数据列表
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author:
		 * @Date: 2023-06-02 09:07:19
		 */
        mainDataGet() {
			let datasList = [];
			let permList = [];
			let dataList = this.selTreeOptList;
			let scopesList = this.departmentName;
			this.mainDataList = [];

			for(let j = 0; j < dataList.length; j++) {
				for(let k = 0; k < scopesList.length; k++) {
					if(dataList[j].id === scopesList[k]) {
						permList.push({
							bussinessId: dataList[j].id,
							name: dataList[j].name,
							organType: dataList[j].organType,
							schoolId: dataList[j].schoolId,
							type: dataList[j].type,
							unionId: dataList[j].unionId
						})
					}
				}
			}

			this.mainDataState = false;
            this._fet("/school/teachingResource/list", {
                schoolId: this.schoolId,
				name:this.resourceName,
				permList: permList
            }).then((res) => {
                if (res.data.code === "200") {
					datasList = res.data.data;
					this.anchorPointList = datasList;
					this.mainDataList = datasList;
					console.log(this.mainDataList,'this.mainDataList=this.mainDataList')
					// 动态赋值锚点定位导航高度
					this.$nextTick(() => {
						setTimeout(() => {
                            if (!document.getElementById("resourceBoesLt")) return;
							document.getElementById("resourceBoesLt").style.height = (this.anchorPointList.length * 36 + 14) + "px";
							document.getElementById("resourceBoesLt").style.maxHeight = (document.body.clientHeight - 280) + "px";
						}, 300);
					});
					// 动态赋值资源列表高度
					this.$nextTick(() => {
						setTimeout(() => {
                            if (!document.getElementById("contents")) return;
							document.getElementById("contents").style.height = (document.body.clientHeight - 280) + "px";
						}, 300);
					});

					setTimeout(() => {
						let coverImg = document.getElementsByClassName("cover-img");
						let coverImgLen = coverImg.length;
						for(let k = 0; k < coverImgLen; k++) {
							if((coverImg[k].naturalHeight / coverImg[k].naturalWidth) < 0.8 && (coverImg[k].naturalHeight / coverImg[k].naturalWidth > 0.5)) {
								coverImg[k].parentNode.classList.add("resource-row-icon_samll");
							} else if((coverImg[k].naturalHeight / coverImg[k].naturalWidth) < 0.8) {
								coverImg[k].parentNode.classList.add("resource-row-icon_min");
							}
						}
					},300)

					this.tableDataLoading = false;
					if(res.data.data.length == 0) {
						this.mainDataState = true;
					} else {
						this.mainDataState = false;
					}
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
					this.tableDataLoading = false;
					this.mainDataState = true;

                }
            });
        },
		/**
		 * @Description: 获取可见范围信息
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-08 10:46:51
		 */
		getScopeInfo() {
			// 弹窗是否开启多选
			// this.multiple = true;
			// 弹窗类型
			this.selectType = "add";
			// 隐藏下拉框
			setTimeout(()=>{
				let selectEle = document.getElementsByClassName("scope-select")[0];
				let selectDropdown = selectEle.parentNode.parentNode.parentNode.parentNode;
				selectDropdown.style.display = "none";
			},100);
			this.showFormTreeDialog();
		},
		/**
		 * @Description: 自动获取链接
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-08 11:59:55
		 */
		getLink() {
			if(this.formData.url === "") {
				this.$message.warning("请输入链接地址！");
				return false;
			}
			this.formData.loading = true;
			this.formData.loadingText = "获取中...";
			this._fet("/school/teachingResource/autoGet", {
			    url: this.formData.url
			}).then((res) => {
			    if (res.data.code === "200") {
					let reg = res.data.data;
					if(reg.name === "" && reg.cover === "" && reg.descNote === "") {
						this.$message.warning("获取资源信息失败，请手动输入所要填写的信息。");
					}
					this.formData.name = reg.name;
					this.formData.cover = reg.cover;
					this.formData.descNote = reg.descNote;
					this.formData.loading = false;
					this.formData.loadingText = "";
			    } else if (res.data && res.data.msg) {
			        this.$message.error(res.data.msg);
					this.formData.loading = false;
					this.formData.loadingText = "";
			    }
			});
		},
        // 保存资源项
        saveItem() {
			let permList =[];
			let dataList =  this.formData.scopeOptions;
			console.log("scopeOptions ", this.formData.scopeOptions)
			for(let j = 0; j < this.formData.scopeOptions.length; j++) {
				permList.push({
					bussinessId: this.formData.scopeOptions[j].bussinessId,
					name: this.formData.scopeOptions[j].name,
					organType: this.formData.scopeOptions[j].organType,
					schoolId: this.formData.scopeOptions[j].schoolId,
					type: this.formData.scopeOptions[j].type,
					unionId: this.formData.scopeOptions[j].unionId
				})
			}

            this.$refs.formRef.validate((valid) => {
                if (valid) {
					if(this.formData.name.length > 20) {
						this.$message.warning("资源名称不可超过20个字");
						return false;
					}
					this.disabledState = true;
					this.formData.loading = true;
					this.formData.loadingText = "保存中...";
                    this._fet("/school/teachingResource/save", {
                        schoolId: this.schoolId,
						permList: permList,
                        ...this.formData
                    }).then((res) => {
                        if (res.data.code === "200") {
							if(this.selectType === "add") {
								this.$message.success("添加成功");
							} else {
								this.$message.success("编辑成功");
							}
							this.formData.loading = false;
							this.formData.loadingText = "";
							this.tableDataLoading = true;
                            this.mainDataGet();
                            this.dialogClose();
							this.formData.scope = [];
							this.formData.scopeOptions = [];
							this.selectAddYetData = [];
							this.selTreeOptList = [];
							this.selectObjData = [];
							this.disabledState = false;
                        } else if (res.data && res.data.msg) {
                            this.$message.error(res.data.msg);
							this.formData.loading = false;
							this.formData.loadingText = "";
							this.disabledState = false;
                        }
                    });
                } else {
                    return false;
                }
            });
        },
		/**
		 * @Description: 添加
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-08 12:03:05
		 */
		additional() {
			this.selectType = "add";
			this.handleClassifyList();
			this.dialogData.dialogVisible = true;
		},
        // 保存账号密码
        saveUserInfo() {
            this.$refs.userInfoRef.validate((valid) => {
                if (valid) {
					this.disabledState = true;
                    this._fet("/school/teachingResource/saveAccountInfo", {
                        ...this.userInfoForm
                    }).then((res) => {
                        if (res.data.code === "200") {
							this.tableDataLoading = true;
                            this.mainDataGet();
                            this.userInfoClose();
							this.disabledState = false;
                        } else if (res.data && res.data.msg) {
                            this.$message.error(res.data.msg);
							this.disabledState = false;
                        }
                    });
                } else {
                    return false;
                }
            });
        },
		/**
		 * @Description: 排序开始事件
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-15 15:13:29
		 */
		sortStateClcik() {
			this.sortState = true;
			this.$nextTick(() => {
				setTimeout(() => {
					document.getElementById("contentes").style.height = (document.body.clientHeight - 280) + "px";
				}, 100);
			});
			setTimeout(() => {
				let coverImg = document.getElementsByClassName("cover-img");
				let coverImgLen = coverImg.length;
				for(let k = 0; k < coverImgLen; k++) {
					if((coverImg[k].naturalHeight / coverImg[k].naturalWidth) < 0.8 && (coverImg[k].naturalHeight / coverImg[k].naturalWidth > 0.5)) {
						coverImg[k].parentNode.classList.add("resource-row-icon_samll");
					} else if((coverImg[k].naturalHeight / coverImg[k].naturalWidth) < 0.8) {
						coverImg[k].parentNode.classList.add("resource-row-icon_min");
					}
				}
			},300)
		},
		/**
		 * @Description: 排序获取资源数据主键
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-13 16:20:16
		 */
		getSrot(dataItem, id) {
			let resourceRoes = document.getElementsByClassName("resource-roes");
			for(let j = 0; j < resourceRoes.length; j++) {
				resourceRoes[j].style.backgroundColor = "";
				if(resourceRoes[j].getAttribute("data-id") === id) {
					resourceRoes[j].style.backgroundColor = "#bfdcfa";
				}

			}

			this.sortDataId = dataItem.id;
		},
		/**
		 * @Description: 拖动事件
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-13 16:20:06
		 */
		itemsDragEnd(event) {
			let data = [];
			if(event) {
				if(event.item.id !== event.to.firstChild.id) {
					this.$message.warning("不可跨分类排序");
					this.mainDataGet();
				} else {

					this.mainDataList.forEach((item, index) => {
						if(item.id === this.sortDataId) {
							item.resourcesList.forEach((sitem, sindex) => {
								data.push({
								    id: sitem.id
								});
							})
						}
					});
					if(data.length > 0) {
						this._fet("/school/teachingResource/infoSort", {
							schoolId: this.schoolId,
							typeId: this.sortDataId,
						    resourceList: data
						}).then((res) => {
						    if (res.data.code === "200") {
								// this.$message.success(res.data.msg);

						    } else if (res.data && res.data.msg) {
						        this.$message.error(res.data.msg);

						    }
						});
					}
				}
			}
		},
		/**
		 * @Description: 排序完成事件
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-15 15:13:59
		 */
        saveSort() {
			this.sortState = false;
			this.$message.success("排序完成");
			this.tableDataLoading = true;
			this.mainDataGet();
        },
		/**
		 * @Description: 重新上传显示
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-08 11:43:17
		 */
		isCoveragse() {
			// console.log('重新上传显示')
			this.isCoverage = true;
		},
		/**
		 * @Description: 重新上传隐藏
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-08 11:43:30
		 */
		notCoveragse () {
			// console.log('重新上传隐藏')
			this.isCoverage = false;
		},
		/**
		 * @Description: 删除图片
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-13 11:57:50
		 */
		deleteClick() {
			console.log("删除图片");
			this.formData.cover = "";
		},
        // 文件上传方法
        uploadSuccess(res) {
            if (res.code === "200") {
                this.formData.cover = res.data.url;
            } else if (res && res.msg) {
                this.$message.error(res.msg);
            }
        },
        uploadUpload(file) {
            const types = [".png", ".jpg", ".jpeg", ".bmp", ".gif"];
            const isImg = types.includes(
                file.name.substring(file.name.lastIndexOf(".")),
            );
            const isLt2M = file.size / 1024 / 1024 < 5;
            const is50 = file.name.length < 50;
            if (!is50) {
                this.$message.error("上传图片名称不能超过50个字符!");
                return false;
            }
            if (!isImg) {
                this.$message.error("上传图片不符合格式!");
                return false;
            }
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过5MB!");
                return false;
            }
        },

		/**
		 * @Description: 分类管理关闭监听
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-07 14:18:21
		 */
		classifyClose() {
			this.dengmiQueryClassify.dialogVisible = false;
			this.dengmiQueryClassify.classifyList = [{
				"id": "",
				"type": "",
				"sort": "",
				"key": Date.now()
			}]
		},
		/**
		 * @Description: 前端动态删除分类
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-07 14:20:19
		 */
		removeDomain(item) {
		    var index = this.dengmiQueryClassify.classifyList.indexOf(item)
		    if (index !== -1) {
		        this.dengmiQueryClassify.classifyList.splice(index, 1)
		    }
		},
		/**
		 * @Description: 接口删除分类
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-12 15:54:11
		 */
		removesDomain(item) {
			this.$confirm("确定删除此分类吗?", "提示", {
			    confirmButtonText: "确定",
			    cancelButtonText: "取消",
			    type: "warning"
			})
			    .then(() => {
			        this._fet("/school/teachingResourceType/delete", {
			            "schoolId": this.schoolId,
						"id": item.id
			        }).then((res) => {
			            if (res.data.code === "200") {
			                this.$message.success(res.data.msg);
							this.tableDataLoading = true;
							this.handleClassifyList();
							this.mainDataGet();
			            } else if (res.data && res.data.msg) {
			                this.$message.error(res.data.msg);
			            }
			        });
			    })
			    .catch(() => {});
		},
		/**
		 * @Description: 前端动态新增分类
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-07 14:20:19
		 */
		addDomain() {
		    this.dengmiQueryClassify.classifyList.push({
		       "id": "",
		       "type": "",
		       "sort": "",
		       "key": Date.now()
		    });
		},
		/**
		 * @Description: 分类拖拽排序
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-12 18:47:15
		 */
		dragend(event){
			let typeList  = [];
			this.dengmiQueryClassify.classifyList.forEach((item, index) => {
			    typeList.push({
					schoolId: item.schoolId,
			        id: item.id
			    });
			});
			this._fet("/school/teachingResourceType/infoSort", {
			    "typeList":  typeList
			}).then((res) => {
			    if (res.data.code === "200") {
					this.$message.success(res.data.msg);
					this.handleClassifyList();
					this.mainDataGet();
			    } else if (res.data && res.data.msg) {
			        this.$message.error(res.data.msg);
			    }
			});
		},
		/**
		 * @Description: 分类管理
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-07 14:20:19
		 */
		classifyManage() {
			this.dengmiQueryClassify.loading = true;
			this.dengmiQueryClassify.dialogVisible = true;
			this.handleClassifyList();
		},
		saveClassify() {
			this.dengmiQueryClassify.dialogVisible = false;
		},
		/**
		 * @Description: 新增分类管理确定
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-12 15:33:12
		 */
		addBlur(item) {

			let dataList = this.dengmiQueryClassify.classifyList;
			//console.log(dataList)
			if(item.type === "") {

			} else {
				this._fet("/school/teachingResourceType/save", {
				    "schoolId": this.schoolId,
					"type": item.type
				}).then((res) => {
				    if (res.data.code === "200") {
						for(let j = 0; j < dataList.length > 0; j++) {
							if(dataList[j].type === "") {
								return false;
							}
						}
						this.$message.success(res.data.msg);

						this.handleClassifyList();
						this.mainDataGet();
				    } else if (res.data && res.data.msg) {
				        this.$message.error(res.data.msg);
				    }
				});
			}
		},
		/**
		 * @Description: 编辑分类管理确定
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-12日15:33:36
		 */
		editBlur(item) {
			let dataList = this.dengmiQueryClassify.classifyList;
			if(item.type === "") {

			} else {
				this._fet("/school/teachingResourceType/edit", {
				    "schoolId": this.schoolId,
					"id": item.id,
					"sort": item.sort,
					"type": item.type
				}).then((res) => {
				    if (res.data.code === "200") {

						for(let j = 0; j < dataList.length > 0; j++) {
							if(dataList[j].type === "") {
								return false;
							}
						}
						this.$message.success(res.data.msg);
						this.handleClassifyList();
						this.mainDataGet();
				    } else if (res.data && res.data.msg) {
				        this.$message.error(res.data.msg);
				    }
				});
			}
		},
		/**
		 * @Description: 批量分配总全选
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-10 18:35:38
		 */
		allsResourceChange(val){
			let dataList = this.dengmiQueryAccredit.dataList;
			this.dengmiQueryAccredit.checkedCities = [];
			// console.log(val)
			if(val) {
				for(let k = 0; k < dataList.length; k++) {
					for(let j = 0; j < dataList[k].resourcesList.length; j++) {
						this.dengmiQueryAccredit.checkedCities.push(dataList[k].resourcesList[j].id)
					}
					dataList[k].isIndeterminate = true;
				}

			}
		},
		/**
		 * @Description: 批量分配单个分类的全选
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-10 18:35:50
		 */
		handleCheckAllChange(val, item, id){
			// console.log(val, item, id)
			let itemsList = [];
			let itemIdList = [];
			itemsList = item.resourcesList;
			for(let j = 0; j < itemsList.length; j++) {
				itemIdList.push(itemsList[j].id)
			}
			let filterArr = this.dengmiQueryAccredit.checkedCities.filter(v => itemIdList.indexOf(v) === -1);
			this.dengmiQueryAccredit.checkedCities = val ? filterArr.concat(itemIdList) : filterArr;
			item.isIndeterminate = false;
		},
		/**
		 * @Description: 批量分配单个选中
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-10 18:36:11
		 */
		handleCheckedCitiesChange(sval, value) {
			// sval -- 所有被选中的资源数据
			// checkedCount -- 所有被选中的资源数据长度
			let checkedCount = sval.length;
			// 当前分类下的资源数据
			let resourcesDataList = value.resourcesList;
			// 当前分类下的资源数据 长度
			let resourcesDataLength = value.resourcesList.length;
			let selectcheckeDataList = [];

			// 双层循环 获取当前分类下被选中的资源数据
			for(let j = 0; j < resourcesDataLength; j++) {
				for(let k = 0; k < checkedCount; k++) {
					if(resourcesDataList[j].id === sval[k]) {
						selectcheckeDataList.push(sval[k]);
					}
				}
			}

			value.checkAll = selectcheckeDataList.length === value.resourcesList.length;
			value.isIndeterminate = selectcheckeDataList.length > 0 && selectcheckeDataList.length < value.resourcesList.length;
		},
		/**
		 * @Description: 批量授权关闭监听
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date:
		 */
		accreditClose() {
			this.dengmiQueryAccredit.dialogVisible = false;
			this.selectObjData =  [];
			this.selectBatchYetData = [];
			this.selTreeOptList = [];
			this.dengmiQueryAccredit.checkedCities = [];
			this.dengmiQueryAccredit.dataList = [];
		},
		/**
		 * @Description: 获取可见范围信息
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date: 2023-06-08 10:46:51
		 */
		getScopesInfo() {
			// 弹窗是否开启多选
			// this.multiple = true;
			// 弹窗类型
			this.selectType = "batch";
			// 隐藏下拉框
			setTimeout(()=>{
				let selectEle = document.getElementsByClassName("scopes-select")[0];
				let selectDropdown = selectEle.parentNode.parentNode.parentNode.parentNode;
				selectDropdown.style.display = "none";
			},100);
			this.showFormTreeDialog();
		},
		/**
		 * @Description: 批量授权
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date:
		 */
		batchAccredit() {
			this.handleBacthList();
			this.dengmiQueryAccredit.dialogVisible = true;
			this.dengmiQueryAccredit.isIndeterminate = true;
		},
		/**
		 * @Description: 批量授权取消
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date:
		 */
		accreditCancel() {
			this.dengmiQueryAccredit.dialogVisible = false;
			this.selectObjData =  [];
			this.selectBatchYetData = [];
			this.selTreeOptList = [];
			this.dengmiQueryAccredit.checkedCities = [];
			this.dengmiQueryAccredit.dataList = [];
			this.disabledState = false;
		},
		/**
		 * @Description: 批量授权确定
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 羡国柱
		 * @Date:
		 */
		accreditConfirm() {
			let permList = [];
			let resourceList = [];
			let dataList = this.dengmiQueryAccredit.scopeOptions;
			let resourceIdList = this.dengmiQueryAccredit.checkedCities;

			for(let j = 0; j < dataList.length; j++) {
				permList.push({
					bussinessId: dataList[j].id,
					name: dataList[j].name,
					organType: dataList[j].organType,
					schoolId: dataList[j].schoolId,
					type: dataList[j].type,
					unionId: dataList[j].unionId
				})
			}

			for(let i = 0; i < resourceIdList.length; i++) {
				resourceList.push({
					id: resourceIdList[i]
				})
			}


			if(this.dengmiQueryAccredit.batchAccreditState) {
				this.$message.warning("您未添加任何教学资源");
				return false;
			}
			this.$refs['dengmiQueryAccredit'].validate((valid) => {
				if (valid) {
					if(resourceList.length > 0) {
						this.disabledState = true;
						this._fet("/school/teachingResource/batchPerm", {
						    "schoolId": this.schoolId,
							"resourceList": resourceList,
							"permList": permList
						}).then((res) => {
						    if (res.data.code === "200") {
								this.$message.success(res.data.msg);
								this.tableDataLoading = true;
								this.handleClassifyList();
								this.mainDataGet();
								this.dengmiQueryAccredit.dialogVisible = false;
								this.dengmiQueryAccredit.scope = [];
								this.dengmiQueryAccredit.scopeOptions = [];
								this.selectBatchYetData = [];
								this.dengmiQueryAccredit.checkedCities = [];
								this.disabledState = false;
						    } else if (res.data && res.data.msg) {
						        this.$message.error(res.data.msg);
								this.disabledState = false;
						    }
						});
					} else {
						this.$message.warning("请选择教学资源");
						this.disabledState = false;
					}
				}
			});

		}

    }
};
</script>

<style lang="scss" scoped>
	.page-box {
        margin-top: 10px;
		.page-content {
			height: calc(100vh - 130px);
		}
	}

	.bar-button {
        display: flex;
        height: 52px;
		margin-bottom: 10px;
        box-sizing: border-box;
        padding: 10px;
        background-color: #FFFFFF;
        .line {
            display: inline-block;
            width: 1px;
            vertical-align: bottom;
            height: 32px;
            border-left: 1px dashed #dcdfe6;
            margin: 0 10px;
        }
	}

	.list-box {
		// overflow: auto;
		overflow-y: auto;
		margin-right: -20px;
		// height: calc(100vh - 195px);
		.item {
			float: left;
			width: 300px;
			height: 215px;
			cursor: pointer;
			margin-right: 20px;
			border-radius: 8px;
			margin-bottom: 20px;
			border: solid 1px #edf0f2;

			.image {
				width: 100%;
				height: 170px;
				display: flex;
				position: relative;
				text-align: center;
				align-items: center;
				justify-content: center;
				background-color: #f0f2f5;

				img {
					max-width: 100%;
					max-height: 100%;
				}
			}
		}
	}

	.info {
		display: flex;
		color: #363b40;
		padding: 0 10px;
		font-size: 14px;
		align-items: center;
		height: calc(100% - 170px);
		justify-content: space-between;

		.name-pass {
			display: flex;
			color: #3C7FFF;
			align-items: center;

			i {
				margin-right: 7px;
			}
		}
	}

	.my-dropdown {
		top: 10px;
		right: 20px;
		position: absolute;
	}

	.drag-layer {
		width: 100%;
		height: 100%;
		display: flex;
		color: #ffffff;
		position: absolute;
		top: 0;
		left: 0;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		border-radius: 6px;
		background: linear-gradient(
			0deg,
			rgba(0, 0, 0, 0.1) 0%,
			rgba(0, 0, 0, 0.4) 100%
		);

		i {
			font-size: 30px;
			margin-bottom: 10px;
		}
	}

	// 数据列表
	.resource-box {
		display: flex;
	}

	// 描点导航
	.resource-boes-lt {
		width: 186px;
		overflow-y: auto;
		background-color: #fff;
		border-radius: 4px;
		padding: 7px 0;
		box-sizing: border-box;
		margin: 0;

		li {
			width: 90%;
			height: 36px;
			font-size: 14px;
			line-height: 36px;
			text-align: center;
			cursor: pointer;
			margin: 0 auto;
		}
		.active {
			color: #fff;
			background: #3C7FFF;
			border-radius: 4px;
		}
	}

	.resource-boes-lt::-webkit-scrollbar {
	    display: none;
	}

	.list-box::-webkit-scrollbar {
	    display: none;
	}



	.limits-list {
		height: 430px;
		overflow-y: auto;
	}

	.step-ul {
	    li {
	        padding-bottom: 20px;
	        .step-icon {
	            width: 13px;
	            height: 13px;
	            margin-right: 20px;
	            border-radius: 50%;
	            z-index: 1;
	        }
	        .step-label {
	            font-weight: 700;
	            font-size: 14px;
	            line-height: 22px;
	            color: #303133;
	        }
	    }
	    .step-active {
	        .step-icon {
	            border: 1px solid #1989fe;
	            background: #fff;
	        }
	        .step-label {
	            color: #1989fe;
	        }
	    }
	}

	.resource-cells-name_icon {
		width: 3px;
		height: 16px;
		margin-top: 4px;
		border-radius: 6px;
		background-color: #3C7FFF;
	}

	.resource-cells-name_text {
		color: #363b40;
		font-size: 16px;
		font-weight: 600;
		margin-left: 5px;
	}

	// 数据列表
	.resource-boes-rt {
		width: 90%;
		margin-left: 12px;
	}

	.resource-cell-name {
		display: flex;
		margin: 12px 0;
	}

	.resource-cell-name:first-child {
		margin-top: 0;
	}

	.resource-cell-info {
		display: flex;
		flex-wrap: wrap;
	}

	.resource-rows {
		display: flex;
		width: 19%;
		min-width: 230px;
		margin: 0 5px;
		margin-bottom: 10px;
		padding: 9px;
		box-sizing: border-box;
		background-color: #fff;
		border-radius: 4px;
	}

	.resource-rows:hover {
		box-shadow: 2px 0px 10px #c0c0c0;
	}

	.btn-icon {
		display: inline-block;
		width: 20px;
		height: 20px;
		background: url('../../assets/images/btns_icon.png') no-repeat center;
		background-size: 100%;
	}

	.resource-row-icon {
		width: 62px;
		height: 62px;
		border: 1px solid #e8e8e8;
		border-radius: 6px;
		overflow: hidden;
	}

	.resource-row-icon img {
		width: 100%;
		// height: 100%;
		display: block;
		margin: 0 auto;
	}

	.resource-row-icon_min img {
		width: 100%;
		display: block;
		margin: 20px auto 0;
	}

	.resource-row-icon_samll img {
		width: 100%;
		display: block;
		margin: 14px auto 0;
	}

	.resource-row-info {
		width: 71%;
		min-width: 126px;
		margin-left: 10px;
	}

	// 资源标题
	.resource-row-info_title {
		width: 100%;
		color: #363b40;
		font-size: 14px;
		font-weight: 600;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.resource-row-info_remark {
		width: 80%;
		color: #999;
		font-size: 12px;
		margin-top: 12px;
		overflow : hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}

	.resource-row-info_url {
		width: 90%;
		color: #999;
		font-size: 12px;
		margin-top: 12px;
		overflow : hidden;
		word-wrap: break-word;
		word-break: normal;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
	}

	.classify-cell {
		transition: transform .3s;
	}

	.classify-cell .move-boxs-info {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 11px;
		height: 34px;
		padding-top: 8px;
		box-sizing: border-box;
		cursor: move;
	}

	.classify-cell .move-boxs-info .move-box-cr {
		width: 2.4px;
		height: 2.2px;
		margin: 0 1px;
		border-radius: 6px;
		background-color: #a9a9a9;

	}

	.classify-cell .move-boxs-info:hover {
		.move-box-cr {
			background-color: #3C7FFF !important;
		}

	}

	.resource-add-from .resource-add-from_class {
		display: flex;
	}

	.resource-add-from .coverage {
		position: absolute;
		top: 0;
		left: 0;
		width: 70px;
		height: 70px;
		text-align: center;
		line-height: 70px;
		color: #fff;
		font-size: 12px;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
	}

	.resource-add-from .coverage-rows {
		width: 50%;
		height: 100%;
		text-align: center;
		line-height: 70px;
	}
</style>
